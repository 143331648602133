import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus' //全局引入
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import 'swiper/css'
import 'element-plus/dist/index.css'

<script>

</script>




createApp(App).use(store).use(router).use(ElementPlus).use(VueAwesomeSwiper).use(VueVideoPlayer).mount('#app')
