<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>
<script>
import { reactive, onMounted } from "vue";
export default {
  name: 'mdmcstore',
  setup() {
    const state = reactive({

    });
    const computed = function () {
      // 设计稿宽度：1920
      const desW = 750
      const devW = document.documentElement.clientWidth
      if (devW < 1280) {
        document.documentElement.style.fontSize = devW / desW * 75 + 'px'
        // 这里的75是使用postcss-px2rem插件时，配置的remUnit的参数值
      } else {
        document.documentElement.style.fontSize = 50 + 'px'
      }
    }


    onMounted(() => {
      computed()
    window.addEventListener('resize', computed, false)

    });

    return {
      state,
      computed

    };
  },
  components: {

  }
}
</script>

<style lang="scss">

@import '@/assets/css/reset.css';
@import '@/assets/font/font.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
