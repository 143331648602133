import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'

import { ref, watch } from "vue";

// PC端路由
const routesPc =  [
    {
       path:'/',redirect:'/website'
    },

  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/privacy.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/permissions',
    name: 'permissions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/permissions.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/useragree',
    name: 'useragree',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/useragree.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/website',
    name: 'pcdmcstorejp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/pcindexjp.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_515',
    name: 'news_515',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_515.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_516',
    name: 'news_516',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_516.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_500',
    name: 'news_500',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_500.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_501',
    name: 'news_501',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_501.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_502',
    name: 'news_502',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_502.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_512',
    name: 'news_512',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_512.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_513',
    name: 'news_513',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_513.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_514',
    name: 'news_514',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_514.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_503',
    name: 'news_503',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_503.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_504',
    name: 'news_504',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_504.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_505',
    name: 'news_505',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_505.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_506',
    name: 'news_506',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_506.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_507',
    name: 'news_507',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_507.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_508',
    name: 'news_508',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_508.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_509',
    name: 'news_509',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_509.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_510',
    name: 'news_510',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_510.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_511',
    name: 'news_511',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/news_511.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },

]


// 移动端路由表
const routesMB = [
    {
        path:'/',redirect:'/website'
     },

  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/privacy.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/permissions',
    name: 'permissions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/permissions.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/useragree',
    name: 'useragree',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/useragree.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_515',
    name: 'news_515',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_515.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_516',
    name: 'news_516',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_516.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_500',
    name: 'news_500',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_500.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_501',
    name: 'news_501',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_501.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_502',
    name: 'news_502',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_502.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_512',
    name: 'news_512',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_512.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_513',
    name: 'news_513',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_513.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_514',
    name: 'news_514',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_514.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_503',
    name: 'news_503',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_503.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_504',
    name: 'news_504',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_504.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_505',
    name: 'news_505',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_505.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_506',
    name: 'news_506',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_506.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_507',
    name: 'news_507',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_507.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_508',
    name: 'news_508',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_508.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_509',
    name: 'news_509',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_509.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_510',
    name: 'news_510',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_510.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/news_511',
    name: 'news_511',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/m/news_511.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
  {
    path: '/website',
    name: 'mdmcstorejp',
    component: () => import('@/views/mindexjp.vue'),
    meta: {
        title: '《航海王：燃烧意志》官方网站-航路不止，燃梦不熄',
        showTab: false,
      },
  },
 
]

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })

// 声明变量用来接routers
var routes = [];
const screenWidth = ref()
// 获取页面宽度
screenWidth.value = document.body.clientWidth
if (screenWidth.value <= 1200) {
    console.log('is Mobile');

    routes = routesMB
} else {
    console.log('pc')
    routes = routesPc
}
window.onresize = () => {
    screenWidth.value = document.body.clientWidth;
};
// 监听页面宽度
watch(screenWidth, (newVal, oldVal) => {
    let timer = setTimeout(() => {
        if (newVal <= 1200) {
            // 小于758就是移动端 就把移动端路由表赋给routes
            console.log('is Mobile');
            routes = routesMB
            window.location.reload()
        } else {
            console.log('pc')
            // 大于就是pc端，把pc端路由表赋给routes
            routes = routesPc
            window.location.reload()
        }
        // 重绘页面
    }, 500)
    return () => {
        // 清除定时器
        clearTimeout(timer)
    }
}, {
    // 深度清除
    deep: true,
})
 
 
 
const router = createRouter({
    history: createWebHashHistory(),
    routes,
   
})


router.beforeEach((to, from, next) => {
    document.title = (to?.meta?.title) || document.title;
    next();
  })
  

export default router
